<template>
  <amf-page id="contact"
            :hasBreadcrumbs="false">

    <template #content>
      <div class="md-alignment-center-center">
        <div class="md-layout md-alignment-center-center">
          <md-card class="md-layout-item md-size-90 md-small-size-100">
            <md-card-content class="contact-content">
              <p style="font-size: 16px; text-align: center;">Please contact us at <a href="mailto:hi@wibki.com">hi@wibki.com</a></p>
            </md-card-content>
          </md-card>
        </div>
      </div>
    </template>
  </amf-page>
</template>

<script>
import { validationMixin } from 'vuelidate';
import {
  required,
  email,
} from 'vuelidate/lib/validators';
import AmfPage from '@/components/page-templates/AmfPage.vue';
import formHelpersMixin from '@/mixins/formHelpersMixin';
import loaderMixin from '@/mixins/loaderMixin';
import { SEND_CONTACT_FORM } from '@/store/modules/emails/emailActions.type';

export default {
  name: 'Contact',
  components: {
    AmfPage,
  },
  mixins: [
    validationMixin,
    formHelpersMixin,
    loaderMixin,
  ],
  data: () => ({
    form: {
      name: null,
      website: null,
      comment: null,
      email: null,
      search: false,
      referringSite: false,
      television: false,
      radio: false,
      newspaper: false,
      wordOfMouth: false,
    },
  }),
  validations: {
    form: {
      email: {
        required,
        email,
      },
    },
  },
  methods: {
    clearForm() {
      this.$v.$reset();
      this.form.name = null;
      this.form.email = null;
      this.form.website = null;
      this.form.comment = null;
      this.form.email = null;
      this.form.search = false;
      this.form.referringSite = false;
      this.form.television = false;
      this.form.radio = false;
      this.form.newspaper = false;
      this.form.wordOfMouth = false;
    },
    saveContactForm() {
      this.$store.dispatch(SEND_CONTACT_FORM, this.form)
        .then((response) => {
          this.$log.info(SEND_CONTACT_FORM, this.form);
          this.$toasted.show(response.message, {
            type: 'success',
            position: 'top-right',
            duration: 5000,
            icon: 'check',
          });
          this.clearForm();
        })
        .catch((error) => {
          this.$log.error(SEND_CONTACT_FORM, error);
          this.$toasted.show(error.message, {
            type: 'error',
            position: 'top-right',
            duration: 5000,
            icon: 'alert',
          });
          if (error.status === 'VALIDATION_ERROR' && error.errors) {
            this.errors = error.errors;
          }
        });
    },
    validateContactForm() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.saveContactForm();
      }
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/scss/layout/contact.scss';
</style>
