<template>
  <div id='main'>
    <div id='title'>
      <h2>Frequently Asked Questions</h2>
    </div>

    <div id="copy">
      <p class="under"><a href="#what-is">So what is Wibki?</a></p>
      <p><a href="#how-do-i-use">How do I use it?</a></p>
      <p><a href="#great-idea">I have a great idea for your site. How do I share it with Wibki?</a></p>
      <p><a href="#contact">I found a great site and think you should add it to Wibki How do I make the
          suggestion?</a></p>
      <h3>My Personalized Homepage</h3>
      <p><a href="#how-do-i-add">How do I add a new site?</a></p>
      <p><a href="#how-delete">How do I delete a site?</a></p>
      <p><a href="#change-location">How do I change a site's location?</a></p>
      <p><a href="#empty-boxes">All the empty boxes in my page are full. How do I add more?</a></p>
      <p><a href="#delet-tab">How do I delete a tab?</a></p>
      <p><a href="#forgot-pass">I forgot my password. How do I retrieve it?</a></p>

      <p><a href="#email">I don't appreciate spam. Does Wibki share my email with a third party?</a></p>
      <p><a href="#private">How can I keep My Faves Private?</a></p>

      <p><a href="#no-help">None of these FAQs has helped me. What do I do?</a></p>
      <br>
      <h3 id="what-is">So what is Wibki?</h3>
      <p>Wibki is a visual bookmarking and discovery service that will change the way you browse
         the web. It's a personalized cross-platform bookmarks tool where you can add and manage all of your favorites in a single visual venue and discover exciting new sites that match your taste.
         And yes, it's totally free.
      </p>
      <h3 id="how-do-i-use">How do I use it?</h3>
      <p>It's very simple. The site is actually a visual list of the most searched for categories on the Web, each
        showcasing 10 different logos of the best sites in that category. We assure you that these sites had to go
        through a strenuous selection process in order to make it to the Wibki homepage... (<a href="#">back to
          top</a>)
      </p>
      <h3 id="great-idea">I have a great idea for your site. How do I share it with Wibki?</h3>
      <p>We're always eager to know what you think of Wibki.. In addition to sending us your feedback, you are
        more than welcome to send us any suggestions you might have that you think can make Wibki a better, more
        rewarding and pleasant site. <a href="/contact" target="blank">Fire away!</a>
        (<a href="#">back to top</a>)</p>
      <h3 id="contact">I found a great site and think you should add it to one of the Favelines. How do I make the
        suggestion?</h3>
      <p>Found an exceptional site? Great! We'll be happy to check it out. Send it to hi@wibki.com or use the <a
          href="/contact">contact us</a> page. If it rocks our world like it rocked
        yours and we feel it's relevant and appropriate we'll add it. (<a href="#">back to top</a>)</p>
      <h3 id="how-do-i-add">How do I add a new site?</h3>
      <p>Click on an empty box in your MyFaves page.</p>
      <p><img class="displayed" src="/images/1.png" alt="MyFaves Page"></p>
      <p>A small window will open, asking you to insert the site's address or URL. Either copy and paste the address or
        type it in (make sure the spelling is right or it will result in an invalid link). In the second <b>Title</b>
        box you can write whatever you want - the text you enter here will appear when you hover your mouse on that
        site's logo.
        <img class="displayed" src="/images/2.png" alt="MyFaves Page">
      </p>
      <p> (<a href="#">back to top</a>)</p>
      <p></p>
      <h3 id="how-delete">How do I delete a site?</h3>
      <p>When logged in, click the Edit button on the MyFaves' right corner. You will then see a pencil icon on all your
        fave boxes. Click the one you want to delete, and click on the trash can icon on the little window that opens
        up. When you're done, click the Edit button one more time to exit the edit mode.
        <img class="displayed" src="/images/3.png" alt="Edit Mode">
        <img class="displayed" src="/images/4.png" alt="Delete">
        (<a href="#">back to top</a>)
      </p>
      <h3 id="change-location">How do I change a site's location?</h3>
      <p>Simply drag and drop the site to where you want it.
      </p>
      <p><img class="displayed" src="/images/5.png" alt="Change a Site's Location">
        <img class="displayed" src="/images/6.png" alt="New Location">
      </p>
      <p>
        (<a href="#">back to top</a>)
      </p>
      <h3 id="empty-boxes">All the empty boxes in my page are full. How do I add more?</h3>
      <p>Ah! It's time to add another tab and start adding more bookmarks. When logged in, click the blue plus sign.
      </p>
      <p><img class="displayed" src="/images/7.png" alt="Press The Plus"></p>
      <p>This will prompt a small window asking you to name the new tab. Once you've given it a name, hit the Save
        button. Voila!</p>
      <p><img class="displayed" src="/images/8.png" alt="Add Tab"></p>
      <p>
        (<a href="#">back to top</a>)
      </p>


      <h3 id="delet-tab"> How do I delete a tab?</h3>
      <p>When logged in, click the round gear icon. This will prompt a small window asking you to edit the tab.
        Click delete in order to proceed with the deletion.</p>
      <!-- <p><img class="displayed" src="//img3.minifav.net/images/faq/addtab.jpg" alt="Press The Big Green Plus"></p>
          <p><img class="displayed" src="//img1.minifav.net/images/faq/addtab2.jpg" alt="Add Tab"></p> !-->
      <p>
        (<a href="#">back to top</a>)
      </p>

      <h3 id="forgot-pass">I forgot my password. How do I retrieve it?</h3>
      <p>In case you've forgotten your password, don't worry. You can easily retrieve it by clicking on "Forgot your
        password?" on the log in page. You'll then be asked to provide your username. Type it in and click on
        Forgot Password. In a minute or so Wibki will send you a new password to your email address.
        (<a href="#">back to top</a>)
      </p>
      <h3 id="email">I don't appreciate spam. Does Wibki share my email with a third party?</h3>
      <p>Absolutely not! Your email address stays with us.
        (<a href="#">back to top</a>)
      </p>
      <h3 id="private">How can I keep My Faves Private?</h3>
      <p>By default, your MyFaves page is open to others, as long as they have the full address of your page. However,
        if you'd like to be the only one with access to it, here's how to make it super-private: Click on
        <b>Settings</b> on the upper right corner of your MyFaves page. Next, check
        the <b>Privacy</b> box. A few seconds after checking and you're all set and secured.
      </p>
      <!-- change the privecy pic to the new one !-->
      <p><img class="displayed" src="/images/9.png" alt="Privecy Setting"></p>
      <p>
        (<a href="#">back to top</a>)
      </p>


      <h3 id="no-help">None of these FAQs has helped me. Help, please?
      </h3>
      <p>If you're experiencing a problem to which none of the above listed FAQs applies, feel free to contact us via
        our <a href="/contact">contact page</a>. We read and reply to each and every
        email we receive because we believe in personal attention and in listening to what our users have to say. As a
        result, we ask that you be patient and we'll reply to your message as soon as possible.
        (<a href="#">back to top</a>)</p>
      <h3>Join Us</h3>
      <p><a href="http://www.facebook.com/getwibki" target="_blank"><img src="/images/fb.gif" alt="Facebook"></a>
        &nbsp; <a href="http://www.twitter.com/getwibki" target="_blank"><img src="/images/tw.gif" alt="Twitter"></a>
      </p>

    </div>

  </div>
</template>

<script>
export default {
  name: 'AboutUs',
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/layout/faq.scss';
</style>
